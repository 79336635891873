import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

const TarifPage = props => {

  const tarifOptions = [
    {"key": "limit_clients", "value": "База клиентов"},
    {"key": "limit_companies", "value": "Свои компании"},
    {"key": "limit_schedule_invoices", "value": "Автоматическое выставление счетов (в месяц)"},
    {"key": "is_bank_integration", "value": "Интеграция с банками"},
    {"key": "is_send_document", "value": "Отправка счетов через электронную почту и мессенджер"},
    {"key": "is_send_report", "value": "Рассылка отчётов (еженедельно, ежемесячно)"},
    {"key": "is_stamp", "value": "Печать и подпись в счёте"},
  ];

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Информация по тарифу</CardTitle>
        <div className="table-responsive">
          {tarifOptions.map((tarifOption, key) => (
            <p key={key}>
              <i className="bx bx-checkbox-square text-primary me-2" />{" "}
              {tarifOption.value}: {props.profileTarif[tarifOption.key] !== null ? (props.profileTarif[tarifOption.key] === true ? "+" : (props.profileTarif[tarifOption.key] === false ? "-" : props.profileTarif[tarifOption.key])) : "∞"}
            </p>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

TarifPage.propTypes = {
  profileTarif: PropTypes.object,
}

export default withTranslation()(TarifPage);
