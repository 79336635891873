import PropTypes from 'prop-types'
import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"

import moment from 'moment'

const CardPricing = props => {
  function getLink(id) {
    return `/tarif/` + id
  }

  let profileTarifRub;
  props.pricing.cost_json.map((el) => {
    if (el.id == 1) {
      profileTarifRub = el.value;
    }
  });

  const tarifOptions = [
    {"key": "limit_clients", "value": "База клиентов"},
    {"key": "limit_companies", "value": "Свои компании"},
    {"key": "limit_schedule_invoices", "value": "Автоматическое выставление счетов (в месяц)"},
    {"key": "is_bank_integration", "value": "Интеграция с банками"},
    {"key": "is_send_document", "value": "Отправка счетов через электронную почту и мессенджер"},
    {"key": "is_send_report", "value": "Рассылка отчётов (еженедельно, ежемесячно)"},
    {"key": "is_stamp", "value": "Печать и подпись в счёте"},
  ];

  return (
    <React.Fragment>
      <Col xl="3" md="6">
        <Card className={props.dateActive != null ? "plan-box shadow-lg border border-primary" : "plan-box"}>
          <CardBody className="p-4">
            <div className="d-flex">
              <div className="flex-grow-1">
                <h5>{props.pricing.name}</h5>
                {props.dateActive != null &&
                  <p className="text-muted">Активна до: {moment(props.dateActive).format('DD.MM.Y HH:mm:ss')}</p>
                }
              </div>
            </div>
            <div className="py-4">
              <h2>
                <sup>
                  <small>₽</small>
                </sup>{" "}
                {parseFloat(profileTarifRub)}/{" "}
                <span className="font-size-13">месяц</span>
              </h2>
            </div>
            <div className="text-center plan-btn">
              {props.dateActive == null &&
                <Link
                  to={getLink(props.pricing.id)}
                  className="btn btn-primary btn-sm waves-effect waves-light "
                >
                  Оплатить
                </Link>
              }
            </div>
            <div className="plan-features mt-5">
              {tarifOptions.map((tarifOption, key) => (
                <p key={key}>
                  <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                  {tarifOption.value}: {props.pricing[tarifOption.key] !== null ? (props.pricing[tarifOption.key] === true ? "+" : (props.pricing[tarifOption.key] === false ? "-" : props.pricing[tarifOption.key])) : "∞"}
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CardPricing.propTypes = {
  pricing: PropTypes.object,
  dateActive: PropTypes.string
}

export default CardPricing
