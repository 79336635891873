import PropTypes from 'prop-types'
import React from "react"

import { Row } from "reactstrap";

//Import Pricing Cards
import CardPricing from "./card-pricing"

import moment from 'moment'

const CardPricings = props => {
  function getDateActive(id) {
    if (id != props.userProfile.profile.tarif.id)
      return null;
    return moment(props.userProfile.profile.tarif.expired_at) > moment.now() ? props.userProfile.profile.tarif.expired_at : null
  }

  return (
    <React.Fragment>
      <Row>
        {props.pricings.map((pricing, key) => (
          <CardPricing pricing={pricing} key={"_pricing_" + key} dateActive={getDateActive(pricing.id)} />
        ))}
      </Row>
    </React.Fragment>
  )
}

CardPricings.propTypes = {
  pricings: PropTypes.array,
  userProfile: PropTypes.object
}

export default CardPricings
