import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Container, Row, Col, Table, Alert } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//Import Pricing Cards
import CardPricings from "./card-pricings"

import withListLoading from '../../components/withListLoading';
import { get } from "helpers/api_helper";

import { useNavigate } from "react-router-dom";

import moment from 'moment'

const Dashboard = props => {

  const navigate = useNavigate();
  //meta title
  document.title = "Личный кабинет | AESbill";

  let userProfile;

  if (localStorage.getItem("authUser")) {
    userProfile = JSON.parse(localStorage.getItem("authUser"));
  } else {
    navigate("/login")
  }
  localStorage.setItem("I18N_LANGUAGE", "rs");

  const ListLoading = withListLoading(CardPricings);
  const [appState, setAppState] = useState({
    loading: false,
    pricings: [],
    userProfile: userProfile,
  });

  useEffect(() => {
    setAppState({ loading: true });
    const apiUrlUser = `/api/v1/user/profile`;
    get(apiUrlUser)
      .then((response) => {
        userProfile.profile = response.data.profile;
        localStorage.setItem("authUser", JSON.stringify(userProfile))
        const apiUrl = `/api/v1/profile/tarifs`;
        get(apiUrl)
          .then((response) => {
            setAppState({ loading: false, pricings: response.data.profile_tarifs, userProfile: userProfile });
          })
          .catch((err) => {
            console.log(err)
            localStorage.removeItem("authUser")
            navigate("/login")
          });
      })
      .catch((err) => {
        console.log(err)
        localStorage.removeItem("authUser")
        navigate("/login")
      });
  }, [setAppState]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={3} sm={4}>
              <Row className="justify-content-center">
                <Col lg={12}>
                  <div className="text-center mb-5">
                    <h4>Информация</h4>
                  </div>
                </Col>
              </Row>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <td><b>ФИО :</b><br />{userProfile.last_name} {userProfile.first_name}</td>
                        </tr>
                        <tr>
                          <td><b>Телефон :</b><br />{userProfile.phone ?? "-"}</td>
                        </tr>
                        <tr>
                          <td><b>Почта :</b><br />{userProfile.email}</td>
                        </tr>
                        <tr>
                          <td><b>Подписка :</b><br />{userProfile.profile.tarif.name}</td>
                        </tr>
                        <tr>
                          <td style={{'borderBottomWidth': 0}}><b>Активна до :</b><br />{moment(userProfile.profile.tarif.expired_at).format('DD.MM.Y')}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              <Alert color="secondary" role="alert">
                С более подробной информацией о возможностях приложений Вы можете ознаĸомится на сайте: <a href="https://aesbill.com" target="_blank" rel="noreferrer">www.aesbill.com</a> или в самом мобильное приложение "AESbill"
              </Alert>
            </Col>
            <Col lg={9} sm={8}>
              <Row className="justify-content-center">
                <Col lg={6}>
                  <div className="text-center mb-5">
                    <h4>Подписки</h4>
                  </div>
                </Col>
              </Row>
              <ListLoading isLoading={appState.loading} pricings={appState.pricings} userProfile={appState.userProfile} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
