import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { Card, CardBody, CardTitle, Container, Row, Col, Table } from "reactstrap";
import {
  useParams, useNavigate, Link
} from "react-router-dom";

//import imgCloudpayments from "../../assets/images/aesbill/cloudpayments.png"
//import imgXamax from "../../assets/images/aesbill/xamax.png"
import imgCloudpayments from "../../assets/images/aesbill/visa_mastercard_mir.png"
import imgXamax from "../../assets/images/aesbill/crypto.png"

//Import Pricing Cards
import TarifPage from "./tarif-page"

import withListLoading from '../../components/withListLoading';

//i18n
import { withTranslation } from "react-i18next";
import { get, post } from "helpers/api_helper";

const Tarif = props => {
  //meta title
  document.title = "Подписка | AESbill";

  let userProfile;

  const navigate = useNavigate();

  if (localStorage.getItem("authUser")) {
    userProfile = JSON.parse(localStorage.getItem("authUser"));
  } else {
    navigate("/login")
  }

  const { id } = useParams();

  const ListLoading = withListLoading(TarifPage);
  const [appState, setAppState] = useState({
    loading: false,
    profileTarif: {},
  });

  useEffect(() => {
    setAppState({ loading: true });
    const apiUrl = `/api/v1/profile/tarifs`;
    get(apiUrl)
      .then((response) => {
        response.data.profile_tarifs.map((row) => {
          if (row.id == id)
            setAppState({loading: false, profileTarif: row})
        })
      })
      .catch((err) => {
        console.log(err)
        localStorage.removeItem("authUser")
        navigate("/login")
      });
  }, [setAppState]);
  
  function widgetOpen() {
    let profileTarifRub;
    appState.profileTarif.cost_json.map((el) => {
      if (el.id == 1) {
        profileTarifRub = el.value;
      }
    });
    const apiUrl = `/api/v1/web-subscription`;
    post(apiUrl, {count_months: 1, profile_tarif_id: appState.profileTarif.id})
      .then((response) => {
        var widget = new cp.CloudPayments();
        widget.pay('charge',
            {
                publicId: process.env.REACT_APP_CLOUDPAYMENTS_PUBLIC_ID,
                description: 'Подписка на ежемесячный доступ к тарифу ' + appState.profileTarif.name,
                amount: parseFloat(profileTarifRub),
                currency: 'RUB',
                accountId: response.user_id,
                invoiceId: response.web_uuid,
                skin: "mini",
                autoClose: 3,
                email: userProfile.email,
                data: {
                  CloudPayments: {
                    recurrent: {
                      interval: 'Month',
                      period: 1, 
                    }
                  }
                }
            },
            {
                onSuccess: function (options) {
                  navigate("/dashboard")
                },
                onFail: function (reason, options) {
                  //
                },
                onComplete: function (paymentResult, options) {
                  //
                }
            }
        )
      })
      .catch((err) => {
        console.log(err)
        localStorage.removeItem("authUser")
        navigate("/login")
      });
  }

  function xamaxOpen() {
    const apiUrl = `/api/v1/web-subscription`;
    post(apiUrl, {count_months: 6, profile_tarif_id: appState.profileTarif.id})
      .then((response) => {
        iFrameXamaxPay({merchant: { key: process.env.REACT_APP_XAMAX_LINK_MERCHANT_KEY, redirect:{"success": process.env.REACT_APP_XAMAX_LINK_REDIRECT , "fail" : process.env.REACT_APP_XAMAX_LINK_REDIRECT ,}}, 'order' : {'name' : 'Оплата подписки ' + appState.profileTarif.name + ' сроком на 6 месяцев' , 'amount' : parseFloat(appState.profileTarif.cost) * 6, 'currency' : 'USD', 'data' : {'web_uuid' : response.web_uuid, 'user_id': response.user_id} }})
      })
      .catch((err) => {
        console.log(err)
        localStorage.removeItem("authUser")
        navigate("/login")
      });
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Link to="/dashboard">← Назад</Link>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-3">
                {appState.profileTarif && (<h4 className="mb-sm-0 font-size-18">Подписка "{appState.profileTarif.name}"</h4>)}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={9}>
              <ListLoading isLoading={appState.loading} profileTarif={appState.profileTarif} />
            </Col>
            <Col lg={3}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Оплатить с помощью:</CardTitle>
                  <img className="rounded mb-3 shadow" style={{ cursor: 'pointer' }} alt="CloudPayments" width="100%" src={imgCloudpayments} data-holder-rendered="true" onClick={widgetOpen} />
                  <img className="rounded mb-3 shadow" style={{ cursor: 'pointer' }} alt="Xamax" width="100%" src={imgXamax} data-holder-rendered="true" onClick={xamaxOpen} />
                  <p className="card-title-desc text-center text-muted">
                    При оплате в криптовалюте будет списан платеж за 6 месяцев
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Tarif.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Tarif);
