export default function authHeader() {
  const obj = JSON.parse(localStorage.getItem("authUser"))

  if (obj && obj.authorization) {
    return {
      email: obj.email,
      authorization: obj.authorization,
      lang: 'ru_RU'
    }
  } else {
    return {lang: 'ru_RU'}
  }
}
