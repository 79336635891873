// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWb51rEkRdYcOkzB1BBcBxXV0Vt-L-iuA",
  authDomain: "aesbill-dev.firebaseapp.com",
  databaseURL: "https://aesbill-dev-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "aesbill-dev",
  storageBucket: "aesbill-dev.appspot.com",
  messagingSenderId: "453032538067",
  appId: "1:453032538067:web:23e7b7b4e5c9d430304926",
  measurementId: "G-2JGNX7ELMZ"
};// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); 