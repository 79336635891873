import React from 'react';
import { auth } from '../firebase-config';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { post } from 'helpers/api_helper';
import { loginSocial } from 'store/actions';
import { useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

const SignInWithGoogle = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        console.log(credential);
        // Пользователь успешно вошел в систему
        // Информация о пользователе доступна в result.user
        const apiUrl = `/api/v1/user/social`;
        let dataAuth = {
          email: result.user?.email ?? result.additionalUserInfo?.profile?.email,
          idToken: credential.idToken
        }
        dispatch(loginSocial(dataAuth, props.router.navigate));
      }).catch((error) => {
        // Обработка ошибок
        console.log(error);
      });
  };

  return (
    <Link
      to="#"
      className="social-list-item bg-danger text-white border-danger"
      onClick={signInWithGoogle}
    >
      <i className="mdi mdi-google" />
    </Link>
  );
}

export default withRouter(SignInWithGoogle);